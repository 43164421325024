import { defineStore } from 'pinia';
import { newApi } from 'src/boot/axios';
import { MarketingKit } from 'components/models';
import { useLoadingStore } from './loading';
import { AppError } from 'src/includes/app-error';

type ThisStoreState = {
  marketing_kits: MarketingKit[];
  mk_completed: boolean | undefined;
  error: AppError | null;
  loaded: boolean;
  fetch_marketing_kits_promise: Promise<void> | null;
};

export const useMarketingKitStore = defineStore('marketingKit', {
  state: () =>
    ({
      marketing_kits: [],
      mk_completed: undefined,
      error: null,
      loaded: false,
      fetch_marketing_kits_promise: null,
    } as ThisStoreState),
  actions: {
    async fetch_marketing_kits(force = false) {
      if (this.fetch_marketing_kits_promise) {
        return this.fetch_marketing_kits_promise;
      }
      if (this.loaded && !force) {
        return;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetch_marketing_kits_promise = newApi
        .get('/marketing_kits/')
        .then((res) => {
          this.loaded = true;
          this.marketing_kits = res.data;
          if (this.marketing_kits.length > 0) {
            this.mk_completed =
              this.marketing_kits[0].questions_no_response == 0;
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetch_marketing_kits_promise = null;
        });
      return this.fetch_marketing_kits_promise;
    },
  },
});
