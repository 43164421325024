import { RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router';
import { IAppPage } from './navigationLinks';
import { useContentPlanStore } from 'src/stores/content-plan';
import { useTopicsStore } from 'src/stores/post-topics';

type IAppRoute = RouteRecordRaw & {
  meta?: {
    breadcrumb:
      | string
      | ((route: RouteLocationNormalizedLoaded) => {
          label: string;
          link?: string;
        });
  };
};

/** Helper function that generates breadcrumbs object for a post route */
const getBreadcrumbForPost = (route: RouteLocationNormalizedLoaded) => {
  const postsStore = useTopicsStore();
  const post_id = parseInt(route.params.post_id as string);
  const post = postsStore.get_topic(post_id);
  const label = (post.topic || post_id) as string;
  return { label };
};

const routes: IAppRoute[] = [
  {
    path: '/',
    meta: {
      breadcrumb: 'Главная',
      icon: 'las la-home',
    },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      // Main / Home
      {
        path: '',
        name: IAppPage.Main,
        component: () => import('pages/Home/HomePageWrapper.vue'),
        meta: {
          breadcrumb: null, // Breadcrumb for home page is defined above
          showBreadcrumbs: false, // Do not show breadcrumbs on the Home page
        },
      },
      // Content Plans
      {
        path: 'content-plans',
        meta: {
          breadcrumb: 'Контент-планы',
          icon: 'las la-stream',
        },
        children: [
          {
            path: '',
            name: IAppPage.ContentPlans,
            component: () =>
              import('src/pages/ContentPlan/ContentPlansPageWrapper.vue'),
            meta: {
              breadcrumb: null, // Avoid duplicate breadcrumb
            },
          },
          {
            path: 'create',
            name: IAppPage.CreateContentPlan,
            component: () =>
              import('src/pages/ContentPlan/CreateContentPlan.vue'),
            meta: {
              breadcrumb: 'Новый контент-план',
            },
          },
          {
            path: ':content_plan_id',
            meta: {
              breadcrumb: (route: RouteLocationNormalizedLoaded) => {
                const content_plan_id = route.params.content_plan_id as string;
                const store = useContentPlanStore();
                const [plan] = store.content_plans.filter(
                  (item) => item.id.toString() === content_plan_id
                );
                const label = plan
                  ? plan.name
                  : `Контент-план ${content_plan_id}`;
                return { label };
              },
            },
            children: [
              {
                path: '',
                name: IAppPage.SingleContentPlan,
                component: () =>
                  import('src/pages/ContentPlan/ContentPlanVer3.vue'),
                props: (route) => ({
                  content_plan_id: Number(route.params.content_plan_id),
                }),
                meta: {
                  breadcrumb: null, // Avoid duplicate breadcrumb
                },
              },
              // Single Post Route
              {
                path: 'post/:post_id',
                meta: {
                  breadcrumb: getBreadcrumbForPost,
                },
                children: [
                  {
                    path: '',
                    name: IAppPage.SinglePost,
                    component: () => import('pages/PostPage.vue'),
                    props: (route) => ({
                      content_plan_id: Number(route.params.content_plan_id),
                      post_id: Number(route.params.post_id),
                    }),
                    meta: {
                      breadcrumb: null, // Avoid duplicate breadcrumb
                    },
                  },
                  // Create or Edit Images for a Post
                  {
                    path: 'images',
                    name: IAppPage.CreateImageForPost,
                    component: () => import('pages/CreateImageForPost.vue'),
                    props: (route) => ({
                      post_id: Number(route.params.post_id),
                    }),
                    meta: {
                      breadcrumb: 'Изображение',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      // Standalone Texts
      {
        path: 'standalone-texts',
        meta: {
          breadcrumb: 'Тексты вне контент-плана',
          icon: 'las la-pen-alt',
        },
        children: [
          {
            path: '',
            name: IAppPage.StandaloneTexts,
            component: () => import('pages/UserPersonalTexts.vue'),
            meta: {
              breadcrumb: null,
            },
          },
          // Single Standalone Text
          {
            path: ':content_plan_id/:post_id',
            name: IAppPage.SingleStandaloneText,
            component: () => import('pages/PostPage.vue'),
            props: (route) => ({
              post_id: Number(route.params.post_id),
              content_plan_id: Number(route.params.content_plan_id),
            }),
            meta: {
              breadcrumb: (route: RouteLocationNormalizedLoaded) => {
                const postsStore = useTopicsStore();
                const post_id = parseInt(route.params.post_id as string);
                const post = postsStore.get_topic(post_id);
                const label = post
                  ? post.topic || `Текст ${post.id}`
                  : `Текст ${post_id}`;
                return { label };
              },
            },
          },
        ],
      },
      // Projects
      {
        path: 'projects',
        meta: {
          breadcrumb: 'Ваши проекты',
        },
        children: [
          {
            path: '',
            name: IAppPage.Projects,
            component: () => import('pages/ManageProfiles.vue'),
            props: (route) => ({
              add_new: Number(route.query.add_new),
            }),
            meta: {
              breadcrumb: null,
            },
          },
        ],
      },
      // Archive
      {
        path: 'archive',
        meta: {
          breadcrumb: 'История',
        },
        children: [
          {
            path: '',
            name: IAppPage.Archive,
            component: () => import('pages/Archive/ArchivePage.vue'),
            meta: {
              breadcrumb: null,
            },
          },
        ],
      },
      // Choose Module
      {
        path: 'choose-module',
        name: IAppPage.ChooseModule,
        component: () => import('pages/ChooseModule.vue'),
        meta: {
          breadcrumb: 'Выбор модуля',
        },
      },
      // Standalone Images
      {
        path: 'standalone-images',
        meta: {
          breadcrumb: 'Изображения',
          icon: 'las la-image',
        },
        children: [
          {
            path: '',
            name: IAppPage.StandaloneImages,
            component: () => import('pages/StandaloneImages.vue'),
            meta: {
              breadcrumb: null,
            },
          },
          // Single Standalone Image
          {
            path: ':content_plan_id/:post_id',
            name: IAppPage.CreateStandaloneImage,
            component: () => import('pages/CreateStandaloneImage.vue'),
            props: (route) => ({
              content_plan_id: Number(route.params.content_plan_id),
              post_id: Number(route.params.post_id),
            }),
            meta: {
              breadcrumb: getBreadcrumbForPost,
            },
          },
        ],
      },
      // Avatars (LoRA Projects)
      {
        path: 'avatars',
        meta: {
          breadcrumb: 'ИИ-фотосессия',
          icon: 'las la-user-cog',
        },
        children: [
          {
            path: '',
            name: IAppPage.LoraProjects,
            component: () => import('pages/LoraProjectsPage.vue'),
            meta: {
              breadcrumb: null,
            },
          },
          {
            path: ':lora_project_id',
            name: IAppPage.AvatarTraining,
            component: () => import('pages/AvatarTrainingPage.vue'),
            props: (route) => ({
              lora_project_id: Number(route.params.lora_project_id),
            }),
            meta: {
              breadcrumb: 'Создание аватара',
            },
          },
        ],
      },
      // Marketing Kit
      {
        path: 'marketing-kit',
        name: IAppPage.MarketingKit,
        component: () => import('pages/MarketingKit.vue'),
        meta: {
          breadcrumb: 'Маркетинг кит',
        },
      },
      // Chat
      {
        path: 'chat/:chat_id?',
        name: IAppPage.Chat,
        component: () => import('pages/ChatPage.vue'),
        props: (route) => ({
          chat_id: Number(route.params.chat_id),
        }),
        meta: {
          breadcrumb: 'ChatGPT',
        },
      },
      // Profile
      {
        path: 'profile',
        name: IAppPage.Profile,
        component: () => import('pages/ProfilePage.vue'),
        meta: {
          breadcrumb: 'Профиль',
        },
      },
      // Admin Posts
      {
        path: 'admin-posts',
        name: IAppPage.AdminPosts,
        component: () => import('pages/AdminPostDetails.vue'),
        meta: {
          breadcrumb: 'Редактирование промптов',
        },
      },
      // Login
      {
        path: 'login',
        meta: {
          breadcrumb: 'Вход',
          showBreadcrumbs: false,
        },
        children: [
          {
            path: 'now/:srv',
            component: () => import('pages/Login.vue'),
            props: true,
            meta: {
              breadcrumb: null,
            },
          },
          {
            path: '',
            name: IAppPage.Login,
            component: () => import('pages/Login.vue'),
            props: false,
            meta: {
              breadcrumb: null,
            },
          },
        ],
      },
      // Logout
      {
        path: 'logout',
        name: IAppPage.Logout,
        component: () => import('pages/Logout.vue'),
        meta: {
          breadcrumb: 'Выход',
          showBreadcrumbs: false,
        },
      },
      // Catch-all Route (404 Not Found)
      {
        path: '/:catchAll(.*)*',
        component: () => import('pages/ErrorNotFound.vue'),
        meta: {
          breadcrumb: 'Страница не найдена',
        },
      },
    ],
  },

  // OAuth Redirect
  {
    path: '/oauth-redirect',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: IAppPage.AuthCallback,
        component: () => import('pages/AuthCallback.vue'),
      },
    ],
  },
];

export default routes;
