import { boot } from 'quasar/wrappers';
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import { useUserStore } from 'stores/user';
import { buildAppError } from 'src/includes/app-error';
import type { AppError } from 'src/includes/app-error';
import eventBus from 'src/includes/event-bus';
import { IAppPage } from 'src/router/navigationLinks';
import * as Sentry from '@sentry/vue';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $newApi: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const newApi = axios.create({
  baseURL: (process.env.BACKEND_SRV as string) + process.env.API_URL_PREFIX,
});

newApi.interceptors.request.use(addAuthorizationToken);

async function addAuthorizationToken(req: InternalAxiosRequestConfig) {
  const userStore = useUserStore();
  const token = userStore.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
}

export default boot(({ app, router }) => {
  newApi.interceptors.response.use(
    async (response) => {
      // Successful response, just return it
      return response;
    },
    async (error: AxiosError) => {
      // Transform the raw error into an AppError (for consistent handling in stores)
      const appError: AppError = buildAppError(error);

      if (appError.status === 401) {
        // Not authenticated - redirect to Login unless the user is already logging in
        if (router.currentRoute.value.name != IAppPage.AuthCallback) {
          router.push({ name: IAppPage.Login });
        }
        // Permission denied, which means expired subscription. Redirect to Profile.
      } else if (appError.status === 403) {
        router.push({ name: IAppPage.Profile });
      } else {
        // Emit Notification Event for all other errors
        eventBus.emit('notification', {
          type: appError.isNetworkError ? 'warning' : 'negative',
          message: appError.message,
        });

        // Report Error to Sentry
        Sentry.captureException(error);
      }

      // Rethrow the AppError so Pinia stores or components can handle it.
      //    In a store action, you'll do: `catch(err) => { // err is now AppError }`
      return Promise.reject(appError);
    }
  );

  // Make Axios and newApi available globally
  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$newApi = newApi;
  // ^ ^ ^ this will allow you to use this.$newApi (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { newApi };
