import { defineStore } from 'pinia';
import { newApi } from 'src/boot/axios';
import { ChatStatus, ChatMessage } from 'components/models';
import { useUserStore } from './user';
import { AppError } from 'src/includes/app-error';
import { useLoadingStore } from './loading';

const COGNISCRIPT_STATUS_TIMEOUT = 60 * 1000; // milliseconds
const COGNISCRIPT_STATUS_REQUESTED = 'requested'; // we are waiting for a response
const COGNISCRIPT_STATUS_AVAILABLE = 'available'; // response available
const COGNISCRIPT_STATUS_CREATED = 'created'; // new chat has just been created, no request sent

const COGNISCRIPT_INITIAL_STATUS: ChatStatus = {
  chat_status: COGNISCRIPT_STATUS_CREATED,
  status_changed: new Date('2021-07-21'),
};

type ThisStoreState = {
  status: ChatStatus;
  chat_messages: ChatMessage[];
  post_id: number;
  error: AppError | null;
  fetch_chat_messages_promise: Promise<void> | null;
};

export const useChatMessagesStore = defineStore('chatMessages', {
  state: () =>
    ({
      status: COGNISCRIPT_INITIAL_STATUS,
      chat_messages: [],
      post_id: 0,
      error: null,
      fetch_chat_messages_promise: null,
    } as ThisStoreState),

  getters: {
    is_text_being_generated(): boolean {
      return this.status.chat_status == COGNISCRIPT_STATUS_REQUESTED;
    },
    instore_status_expired(): boolean {
      const latest_not_expired = new Date(
        Date.now() - COGNISCRIPT_STATUS_TIMEOUT
      );

      return this.status.status_changed < latest_not_expired;
    },
    get_chat_messages(state) {
      return (post_id: number) => {
        /**
         * Empty chat messages is a valid situation and we don't want to keep
         * reloading them in that case. So we verify for post_id only.
         */
        if (state.post_id != post_id) {
          return [];
        } else {
          return state.chat_messages;
        }
      };
    },
    get_chat_message(state) {
      return (message_id: number) => {
        if (!message_id || !state.chat_messages) {
          return;
        }
        const found_messages = state.chat_messages.filter(function (msg) {
          return msg.id == message_id;
        });
        if (found_messages.length > 0) {
          return found_messages[0];
        }
      };
    },
    get_status(state) {
      return (post_id: number) => {
        if (state.post_id != post_id) {
          return COGNISCRIPT_INITIAL_STATUS;
        } else {
          return state.status;
        }
      };
    },
  },
  actions: {
    async fetch_chat_messages(post_id?: number) {
      if (this.fetch_chat_messages_promise) {
        return this.fetch_chat_messages_promise;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      if (!post_id) {
        post_id = this.post_id;
      }
      this.fetch_chat_messages_promise = newApi
        .get('/chat_messages/' + post_id)
        .then((res) => {
          this.chat_messages = Array.isArray(res.data) ? res.data : [];
          this.post_id = post_id;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetch_chat_messages_promise = null;
        });
      return this.fetch_chat_messages_promise;
    },

    fetch_chat_status(post_id: number, ignore_local_status = false) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      newApi
        .get('/chats/' + post_id)
        .then((res) => {
          /**
           * We begin with a consistncy check. If for whatever reason, the stored
           * post_id is different, all the stored data needs to be discarded.
           */
          if (this.post_id != post_id) {
            this.status = COGNISCRIPT_INITIAL_STATUS;
            this.chat_messages = [];
            this.fetch_chat_messages(post_id);
          }
          this.post_id = post_id;

          let status_in_db: ChatStatus;

          if (res.status == 204) {
            /**
             * HTTP_204_NO_CONTENT -- this means, there is no existing chat in the
             * DB for this post yet. This is equivalent for a chat to be just
             * created, so make up this status locally (instead of polluting the DB
             * by actually creating this object, which may turn out to be not
             * actually needed.)
             */
            status_in_db = {
              chat_status: COGNISCRIPT_STATUS_CREATED,
              status_changed: new Date(),
            };
          } else {
            status_in_db = res.data;
            status_in_db.status_changed = new Date(status_in_db.status_changed);
          }

          // console.log('local_status = ', state.status);
          // console.log('status_from_db = ', status_in_db);

          /**
           * Now our goal is to determine whether we should update the local
           * in-memory status with the one just read from the DB. We
           * want to do the update in the following cases:
           * 1) Local status not set
           * 1) There is a newer status in DB
           * 2) A predefined timeout has elapsed since the local status was set
           *    (that would mean something went wrong and we basically want to
           *    ignore the previous local status setting)
           */

          // if (status_in_db.status_changed > state.status.status_changed) {
          //   console.log('Status in DB is newer');
          // }

          // if (instore_status_expired(state)) {
          //   console.log('Instore status expired');
          // }

          if (
            ignore_local_status ||
            status_in_db.status_changed > this.status.status_changed ||
            this.instore_status_expired
          ) {
            if (
              this.status.chat_status == COGNISCRIPT_STATUS_REQUESTED &&
              status_in_db.chat_status == COGNISCRIPT_STATUS_AVAILABLE
            ) {
              //A new text just became available.
              this.fetch_chat_messages(post_id);
            }
            this.status = status_in_db;
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => loadingStore.stopLoading());
    },
    mark_read(message_id: number) {
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        this.chat_messages[m_index].seen = true;
      }
      newApi.patch('/chat_messages/' + message_id).catch((error) => {
        this.error = error;
      });
    },
    delete_chat_message(message_id: number) {
      /**
       * First, delete on the backend and then, if successful, update the
       * front-end chat_messages array.
       * We used to do it the other way around but it presents a problem when
       * we need to watch 'chat_messages' and re-fetch the topics (user-provided
       * texts) when the last chat_message is deleted. The re-fetch in this case
       * must happen after the message has been deleted on the backend.
       */
      newApi
        .delete('/chat_messages/' + message_id)
        .then(() => {
          const m_index = this.chat_messages.findIndex(
            (message) => message.id == message_id
          );
          if (m_index >= 0) {
            this.chat_messages.splice(m_index, 1);
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    save_updated_chat_message(message_id: number) {
      /**
       * We are assuming the message has already been updated in the store, and
       * now our goal is to propagate the update to the backend.
       */
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        newApi
          .put(
            '/chat_messages/' + message_id,
            this.chat_messages[m_index].content
          )
          .then(() => {
            this.chat_messages[m_index].edited_at = Date.now() / 1000;
          })
          .catch((error) => {
            this.error = error;
          });
      }
    },
    save_feedback_on_message(message_id: number, positive: boolean) {
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        this.chat_messages[m_index].user_feedback = positive ? 1 : -1;
        newApi
          .post(
            '/chat_messages/' + message_id,
            this.chat_messages[m_index].user_feedback
          )
          .catch((error) => {
            this.error = error;
          });
      }
    },
    async delete_chat() {
      newApi
        .delete('/chats/' + this.post_id)
        .then((res) => {
          this.chat_messages = [];
          this.status = res.data;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    request_text(prompt_id: number) {
      newApi
        .post('/chats/' + this.post_id + '/' + prompt_id)
        .then(() => {
          const user = useUserStore();
          if (
            user.user.subscription_plan?.has_limit &&
            user.user.credits_remaining > 0
          ) {
            user.user.credits_remaining--;
          }
        })
        .catch((error) => {
          this.error = error;
        });
      this.set_requested_status();
    },
    set_requested_status() {
      this.status = {
        chat_status: COGNISCRIPT_STATUS_REQUESTED,
        status_changed: new Date(),
      };
    },
    force_status_update(ignore_local_status = false) {
      this.fetch_chat_status(this.post_id, ignore_local_status);
    },
  },
});
