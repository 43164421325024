import { useUserStore } from 'src/stores/user';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export interface INavigationLink {
  title: string;
  icon: string;
  visible?: boolean | (() => boolean);
  link: RouteLocationRaw;
  locked?: boolean;
}

export enum IAppPage {
  Main = 'Main',
  Projects = 'Projects',
  StandaloneImages = 'Images',
  CreateStandaloneImage = 'SingleStandaloneImage',
  CreateImageForPost = 'CreateImageForPost',
  LoraProjects = 'LoraProjects',
  AvatarTraining = 'AvatarTraining',
  ChooseModule = 'ChooseModule',
  Chat = 'Chat',
  Profile = 'Profile',
  Login = 'Login',
  Logout = 'Logout',
  AuthCallback = 'OAuthRedirect',
  MarketingKit = 'MarketingKit',
  Archive = 'Archive',
  ContentPlans = 'ContentPlans',
  SingleContentPlan = 'SingleContentPlan',
  CreateContentPlan = 'CreateContentPlan',
  SinglePost = 'SinglePost',
  AdminPosts = 'AdminPosts',
  StandaloneTexts = 'StandaloneTexts',
  SingleStandaloneText = 'SingleStandaloneText',
}

export const useNavigationLinks = () => {
  const userStore = useUserStore();
  const user = computed(() => userStore.user);
  const isTrialUser = computed(() => user.value.subscription_plan.is_trial);
  const hasLoraAddon = computed(() => userStore.hasLoraAddon);

  /** A list of top-level navigation locations (displayed inside the drawer) */
  const navigationLinks = computed((): INavigationLink[] => [
    {
      title: 'Главная',
      icon: 'las la-home',
      link: {
        name: IAppPage.Main,
      },
      visible: true,
    },
    {
      title: 'Проекты',
      icon: 'las la-project-diagram',
      link: {
        name: IAppPage.Projects,
      },
      visible: true,
    },
    {
      title: 'Выбрать модуль',
      icon: 'las la-search-plus',
      link: {
        name: IAppPage.ChooseModule,
      },
      visible: () => userStore.can_change_module || user.value.is_admin,
    },
    {
      title: 'Контент-планы',
      icon: 'las la-stream',
      link: {
        name: IAppPage.ContentPlans,
      },
      visible: true,
    },
    {
      title: 'Тексты вне контент-плана',
      icon: 'las la-pen-alt',
      link: {
        name: IAppPage.StandaloneTexts,
      },
      visible: true,
    },
    {
      title: 'ИИ-фотосессия',
      icon: 'las la-user-cog',
      link: {
        name: IAppPage.LoraProjects,
      },
      visible: true,
      locked: !hasLoraAddon.value,
    },
    {
      title: 'Изображения',
      icon: 'las la-image',
      link: {
        name: IAppPage.StandaloneImages,
      },
      visible: true,
    },
    {
      title: 'ChatGPT',
      icon: 'las la-comments',
      link: {
        name: IAppPage.Chat,
      },
      visible: true,
      locked: isTrialUser.value,
    },
    {
      title: 'Редактировать промпты',
      icon: 'las la-key',
      link: {
        name: IAppPage.AdminPosts,
      },
      visible: () => user.value.is_admin,
    },
    {
      title: 'Профиль',
      icon: 'las la-user',
      link: {
        name: IAppPage.Profile,
      },
      visible: true,
    },
    {
      title: 'Маркетинг кит',
      icon: 'las la-edit',
      link: {
        name: IAppPage.MarketingKit,
      },
      visible: true,
    },
    {
      title: 'История',
      icon: 'las la-archive',
      link: {
        name: IAppPage.Archive,
      },
      visible: true,
    },
  ]);

  return navigationLinks;
};
