import { defineStore } from 'pinia';
import { LocalStorage } from 'quasar';
import { newApi } from 'src/boot/axios';
import {
  ContentPlan,
  SubscriptionPlan,
  User,
  UserProfile,
} from 'components/models';
import { useContentPlanStore } from './content-plan';
import { useChatMessagesStore } from './chat-messages';
import { useQuestionsStore } from './questions';
import { useContentPlansStore } from './choose-module';
import { useMarketingKitStore } from './marketing-kit';
import { useTopicsStore } from './post-topics';
import { useLoadingStore } from './loading';
import { RouteLocationRaw } from 'vue-router';
import { IAppPage } from 'src/router/navigationLinks';
import type { AppError } from 'src/includes/app-error';

const USER_ID_UNAUTHENTICATED = -1;
const USER_ID_NOT_FOUND = 0;

const EMPTY_CONTENT_PLAN: ContentPlan = {
  id: 0,
  name: '',
  description: '',
  is_adaptive: false,
  is_for_projects_home_page: false,
  content_plan_group_id: 0,
  content_plan_group: {
    id: 0,
    description: '',
  },
  video_1_id: '',
  video_2_id: '',
  new_content_plan_text: '',
};

const EMPTY_PROFILE: UserProfile = {
  id: 0,
  profile_label: '',
  content_plan_id: 0,
  personal_texts_id: 0,
  image_requests_id: 0,
  content_plan: EMPTY_CONTENT_PLAN,
};

const EMPTY_SUBSCRIPTION_PLAN: SubscriptionPlan = {
  id: 0,
  name: '',
  description: '',
  limit_description: '',
  has_limit: false,
  initial_limit: 0,
  trial_wo_card: false,
  max_num_profiles: 0,
  is_trial: true,
};

const NO_USER: User = {
  id: USER_ID_UNAUTHENTICATED,
  reporting_user_id: 0,
  first_name: '',
  last_name: '',
  email: '',
  disabled: false,
  can_change_module: false,
  is_admin: false,
  needs_to_be_told_about_projects: false,
  credits_remaining: 0,
  show_credits_counter: false,
  subscription_url: '',
  subscription_plan: EMPTY_SUBSCRIPTION_PLAN,
  active_profile: EMPTY_PROFILE,
  preferences: {
    hide_gen_confirm: false,
  },
  active_org: {
    name: '',
    subscription_plan: EMPTY_SUBSCRIPTION_PLAN,
  },
  addons: [],
};

const HIDE_GEN_CONFIRM_STORAGE_KEY = 'cogniscript_hide_gen_confirm';
const LOGIN_NOW_AUTH_FLOW_STORAGE_KEY = 'cogniscript_login_now_initiated';

type ThisStoreState = {
  user: User;
  user_profiles: UserProfile[];
  error: AppError | null;
  token: string | null;
  isInitialized: boolean;
  fetchUserPromise: Promise<void> | null;
  fetchUserProfilesPromise: Promise<void> | null;
};

export const useUserStore = defineStore('user', {
  state: (): ThisStoreState => ({
    user: NO_USER,
    user_profiles: [],
    error: null,
    token: LocalStorage.getItem('cogniscript_id_token'),
    isInitialized: false,
    fetchUserPromise: null,
    fetchUserProfilesPromise: null,
  }),

  getters: {
    not_found_in_db(state): boolean {
      return state.user.id === USER_ID_NOT_FOUND;
    },
    is_authenticated(state): boolean {
      return state.user.id > USER_ID_UNAUTHENTICATED;
    },
    is_authorized(state): boolean {
      return this.is_authenticated && !state.user.disabled;
    },
    can_change_module(state): boolean {
      return state.user.can_change_module;
    },
    has_content_plan_for_project_based_home_page(state): boolean {
      return state.user.active_profile.content_plan?.is_for_projects_home_page;
    },
    has_adaptive_content_plan(state): boolean {
      return state.user.active_profile.content_plan?.is_adaptive;
    },
    content_plan_id(state): number {
      if (this.is_authorized && this.has_adaptive_content_plan) {
        const content_plan_store = useContentPlanStore();

        if (
          content_plan_store.have_accepted_generated_content_plan &&
          content_plan_store.get_active_content_plan_id
        ) {
          return content_plan_store.get_active_content_plan_id;
        }
      } else if (state.user.active_profile.content_plan_id) {
        return state.user.active_profile.content_plan_id;
      }
      return -1;
    },
    link_to_accepted_content_plan(): RouteLocationRaw {
      return {
        name: IAppPage.SingleContentPlan,
        params: { content_plan_id: this.content_plan_id },
      };
    },
    link_to_content_plans_page(state): RouteLocationRaw | string {
      const content_plan_id = this.content_plan_id;

      if (this.has_adaptive_content_plan) {
        return { name: IAppPage.ContentPlans };
      } else if (content_plan_id > 0) {
        return {
          name: IAppPage.SingleContentPlan,
          params: {
            content_plan_id: state.user.active_profile.content_plan_id,
          },
        };
      }

      return '';
    },
    login_now_srv(): string | null {
      return LocalStorage.getItem(LOGIN_NOW_AUTH_FLOW_STORAGE_KEY);
    },
    hasAddOn(state) {
      return (addonId: number): boolean => {
        return state.user.addons.some((addon) => addon.id === addonId);
      };
    },
    getLoraProjectLimit(state): number {
      const loraAddons = state.user.addons?.filter((addon) =>
        this.constants.loraSettings.addonSubscriptionPlanIds.includes(addon.id)
      );
      if (loraAddons?.length > 0) {
        // Higher plan overrides lower ones
        // max_num_profiles is the project limit for LoRA
        return Math.max(
          ...loraAddons.map((addon) => addon.max_num_profiles || 0)
        );
      }
      return 0;
    },
    hasLoraAddon(): boolean {
      return this.getLoraProjectLimit > 0;
    },
  },

  actions: {
    async fetchUser(force = false) {
      if (this.fetchUserPromise) {
        return this.fetchUserPromise;
      }
      if (this.isInitialized && !force) {
        return;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetchUserPromise = newApi
        .get('/user/')
        .then((res) => {
          this.user = res.data;
          if (!this.user.active_profile) {
            this.user.active_profile = { ...EMPTY_PROFILE };
          }
          if (!this.user.subscription_plan) {
            this.user.subscription_plan = { ...EMPTY_SUBSCRIPTION_PLAN };
          }
          if (!this.user.preferences) {
            this.user.preferences = {
              hide_gen_confirm: false,
            };
          }
          this.user.preferences.hide_gen_confirm = LocalStorage.getItem(
            HIDE_GEN_CONFIRM_STORAGE_KEY
          )
            ? true
            : false;
        })
        .catch((error) => {
          this.error = error as AppError;
          if (this.error.status === 401) {
            this.user.id = USER_ID_UNAUTHENTICATED;
          }
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetchUserPromise = null;
          this.isInitialized = true;
        });
      return this.fetchUserPromise;
    },

    async fetchUserProfiles(force = false) {
      if (this.fetchUserProfilesPromise) {
        return this.fetchUserProfilesPromise;
      }
      if (this.user_profiles.length > 0 && !force) {
        return;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetchUserProfilesPromise = newApi
        .get('/profiles/')
        .then((res) => {
          this.user_profiles = res.data;
        })
        .catch((error) => {
          this.error = error as AppError;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetchUserProfilesPromise = null;
        });
      return this.fetchUserProfilesPromise;
    },

    set_token(token: string) {
      this.token = token;

      if (token) {
        LocalStorage.set('cogniscript_id_token', token);
      } else {
        LocalStorage.remove('cogniscript_id_token');
      }
    },

    logout() {
      this.set_token('');
      this.user = { ...NO_USER };
      this.user_profiles = [];
    },

    set_and_save_hide_gen_confirm(value: boolean) {
      if (!this.user.preferences) {
        this.user.preferences = {
          hide_gen_confirm: value,
        };
      } else {
        this.user.preferences.hide_gen_confirm = value;
      }
      LocalStorage.set(HIDE_GEN_CONFIRM_STORAGE_KEY, value);
    },

    set_login_now_srv(value: string) {
      LocalStorage.set(LOGIN_NOW_AUTH_FLOW_STORAGE_KEY, value);
    },

    async add_new_user_profile(profile_label: string, copy_from?: UserProfile) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      try {
        const res = await newApi.post('/profiles/', {
          profile_label: profile_label,
          copy_from_id: copy_from ? copy_from.id : 0,
        });
        const new_profile: UserProfile = res.data;
        this.user_profiles.push(new_profile);
      } catch (error) {
        this.error = error as AppError;
      } finally {
        loadingStore.stopLoading();
      }
    },

    async rename_user_profile(profile_id: number, new_profile_label: string) {
      const profile = this.user_profiles.find(
        (profile) => profile.id === profile_id
      );
      if (!profile) {
        return;
      }
      profile.profile_label = new_profile_label;
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      try {
        await newApi.put(`/profiles/${profile_id}`, new_profile_label);
      } catch (error) {
        this.error = error as AppError;
      } finally {
        loadingStore.stopLoading();
      }
    },

    async choose_user_profile(profile_id: number) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      try {
        await newApi.patch(`/profiles/${profile_id}`);
        // Reset dependent stores
        const chat_messages = useChatMessagesStore();
        chat_messages.$reset();

        const questions_store = useQuestionsStore();
        questions_store.$reset();

        const modules_store = useContentPlansStore();
        modules_store.$reset();

        const content_plan_store = useContentPlanStore();
        content_plan_store.$reset();
        content_plan_store.save_active_content_plan_id(0);

        const mk_store = useMarketingKitStore();
        mk_store.$reset();

        const topics_store = useTopicsStore();
        topics_store.$reset();

        await this.fetchUser(true);
      } catch (error) {
        this.error = error as AppError;
        throw error;
      } finally {
        loadingStore.stopLoading();
      }
    },

    async delete_user_profile(profile_id: number) {
      const p_index = this.user_profiles.findIndex(
        (profile) => profile.id == profile_id
      );
      if (p_index < 0) {
        return;
      }
      this.user_profiles.splice(p_index, 1);
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      try {
        await newApi.delete(`/profiles/${profile_id}`);
      } catch (error) {
        this.error = error as AppError;
      } finally {
        loadingStore.stopLoading();
      }
    },

    async update_user() {
      this.error = null;
      this.user.needs_to_be_told_about_projects = false;
      try {
        await newApi.patch('/user/', this.user);
      } catch (error) {
        this.error = error as AppError;
      }
    },
  },
});
